import React, {useCallback} from 'react'
import {useTranslation} from 'next-i18next'
import Text from '@component/Text/Text'
import useFormatNumber from '@hook/useFormatNumber'
import useFormatDate from '@hook/useFormatDate'
import {getDateFromYYYYMMDD} from '@util/dates'
import {getNumberColorClass} from '@util/etc'
import {IKeyPeriodPnl} from '@api/key/portfolio/getKeyDailyPnl'
import {useRouter} from 'next/router'
import {TableHeaderContainer} from '@component/tables/TableHeaderContainer'
import WizTable, {IWizTableColumn} from '@component/tables/WizTable/WizTable'
import TableHeader from '@component/tables/TableHeader'
import Spinner from '@component/Spinner/Spinner'

type KeyPnlTableType = 'short' | 'page'

interface IProps {
    data: IKeyPeriodPnl[]
    className?: string
    renderHeader?: () => React.ReactNode
    onClick?: () => void
    period?: string | number
    type?: KeyPnlTableType
    renderType?: 'copyfolio' | 'default'
    isFetching?: boolean
}

const KeyPnlTable: React.FC<IProps> = ({
    data = [],
    className,
    onClick,
    renderHeader,
    period = 'daily',
    type = 'short',
    renderType = 'default',
    isFetching,
}) => {
    const {t} = useTranslation()
    const {displayBalance} = useFormatNumber()
    const {displayDateMilliSeconds} = useFormatDate()
    const router = useRouter()

    const renderEmpty = () => {
        return isFetching ? (
            <div className={'flex min-h-[84px] items-center justify-center'}>
                <Spinner size={'smallest'} visible={true} />
            </div>
        ) : (
            <div className={'flex min-h-[84px] items-center justify-center bg-white dark:bg-bg_dark_white01'}>
                <Text className={'text-body1 text-gray02 dark:text-dark_gray02'}>{t('table.dailyPnl.empty.text')}</Text>
            </div>
        )
    }

    const columns: IWizTableColumn<IKeyPeriodPnl>[] = [
        {
            key: 'date',
            header: () => <TableHeader title={t('table.dailyPnl.column.date')} />,
            cell: item => (
                <Text className={'text-body2 text-gray01 dark:text-dark_gray01 py-[10px]'}>
                    {displayDateMilliSeconds(getDateFromYYYYMMDD(item.day).getTime(), t('dateFormat.date'))}
                </Text>
            ),
            visibilityClassName: 'w-[48%] md:w-[auto]',
        },
        {
            key: 'pnl',
            header: () => <TableHeader title={t('table.dailyPnl.column.pnl')} className={'md:justify-end'} />,
            cell: item => (
                <Text className={`text-body2 ${getNumberColorClass(item.earning)} md:text-right`}>
                    {displayBalance(item.earning, {showPreSign: true})}
                </Text>
            ),
            visibilityClassName: 'w-[48%] md:w-[auto]',
        },
    ]

    const getRenderDate = useCallback(
        (item: IKeyPeriodPnl) => {
            if (period === 'daily') {
                return (
                    <Text className={'text-body1 text-gray01 dark:text-dark_gray01'}>
                        {displayDateMilliSeconds(getDateFromYYYYMMDD(item?.day).getTime(), t('dateFormat.date'))}
                    </Text>
                )
            } else {
                const dateStr = String(item?.day)
                const year = dateStr.slice(0, 4)
                const month = dateStr.slice(4)
                const date = new Date(`${year}-${month}`).getTime()
                if (router?.locale === 'ko') {
                    return (
                        <Text className={'text-body1 text-gray01 dark:text-dark_gray01'}>
                            {t('dateFormat.locale.month', {year: year, month: month})}
                        </Text>
                    )
                } else {
                    return (
                        <Text className={'text-body1 text-gray01 dark:text-dark_gray01'}>
                            {displayDateMilliSeconds(date, t('dateFormat.month'))}
                        </Text>
                    )
                }
            }
        },
        [period, t, displayDateMilliSeconds, router?.locale],
    )

    const getRenderEarning = useCallback(
        (item: IKeyPeriodPnl) => {
            return (
                <Text className={`text-body1 sm:text-right ${getNumberColorClass(item.earning)}`}>
                    {displayBalance(item.earning, {showPreSign: true})}
                </Text>
            )
        },
        [displayBalance],
    )

    return type === 'short' ? (
        <>
            <WizTable
                className={`${className} border border-transparent02 dark:border-bg_dark_white01 shadow-normal rounded-[5px] mt-[5px]`}
                data={data ?? []}
                columns={columns}
                renderHeader={renderHeader}
                renderType={'copyfolio'}
                itemRowClassName={() => {
                    return 'dark:bg-bg_dark_white01'
                }}
                extractKey={item => item.id}
                renderEmpty={renderEmpty}
                onClick={onClick}
                header={{
                    borderClassName: `${
                        renderHeader ? 'border-y' : 'border-b'
                    } border-transparent01 dark:border-bg_dark_white01`,
                    bgClassName: 'bg-gray09 dark:bg-bg_dark_white02',
                    paddingHorizontal: 20,
                }}
            />
        </>
    ) : (
        <>
            <TableHeaderContainer className={`grid grid-cols-2 mt-[10px] ${className}`}>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>{t('table.dailyPnl.column.date')}</Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02 sm:text-right'}>
                    {t('table.dailyPnl.column.pnl')}
                </Text>
            </TableHeaderContainer>
            {data?.length > 0 && (
                <>
                    {data?.map(item => (
                        <div key={item.day} className={`grid grid-cols-2 py-[15px] px-[20px] items-center `}>
                            <div>{getRenderDate(item)}</div>
                            <div>{getRenderEarning(item)}</div>
                        </div>
                    ))}
                </>
            )}
        </>
    )
}

export default React.memo(KeyPnlTable)
