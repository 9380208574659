import React, {useRef} from 'react'
import Text from '@component/Text/Text'
import {TableHeaderContainer} from '@component/tables/TableHeaderContainer'
import ExchangeIcon from '@component/images/ExchangeIcon'
import IconNext from '@svg/common/ic_next.svg'
import {getNumberColorClass} from '@util/etc'
import {ICopyHistory} from '@api/copy/copier/history/getCopyHistory'
import {useTranslation} from 'next-i18next'
import useFormatDate from '@hook/useFormatDate'
import useFormatNumber from '@hook/useFormatNumber'
import CopyHistoryDetailModal from '@feature/portfolio/components/CopyHistoryDetailModal/CopyHistoryDetailModal'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import {createLog} from '@util/logs'
import Spinner from '@component/Spinner/Spinner'

interface IProps {
    copyHistory: ICopyHistory[]
    isFetching?: boolean
}

const UserCopyHistoryTable: React.FC<IProps> = ({copyHistory, isFetching}) => {
    const {t} = useTranslation()
    const {data: me} = useQueryFetchMe(undefined, false)
    const {getExchange} = useQueryGetExchanges()
    const {displayDateFromText} = useFormatDate()
    const {displayBalance} = useFormatNumber()
    const historyDetailModalRef = useRef<any>()

    const onClickCopyHistoryDetailModal = (item: ICopyHistory) => {
        createLog('event', 'copyfolio_history_detail', {copyfolio_id: item.id})
        historyDetailModalRef?.current?.show(item)
    }

    return (
        <>
            <TableHeaderContainer
                className={
                    'flex items-center gap-[15px] grid grid-cols-[270px_260px_auto_120px] md:grid-cols-4 px-4 md:hidden mt-[10px] border !border-gray06 dark:!border-dark_gray06 dark:bg-bg_dark_white02'
                }>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                    {t('copyHistory.table.column.myKey.title')}
                </Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                    {t('copyHistory.table.column.leaderKey.title')}
                </Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02'}>
                    {t('copyHistory.table.column.period.title')}
                </Text>
                <Text className={'text-ti2 text-gray02 dark:text-dark_gray02 text-right'}>
                    {t('copyHistory.table.column.profit.title')}
                </Text>
            </TableHeaderContainer>

            {isFetching ? (
                <Spinner visible={isFetching} size={'small'} className={'mx-auto mt-[30px]'} />
            ) : (
                copyHistory?.map(item => {
                    return (
                        <div
                            onClick={() => onClickCopyHistoryDetailModal(item)}
                            key={item.id}
                            className={
                                'rounded-lg shadow-normal items-center gap-[15px] sm:gap-[12px] grid border border-transparent bg-white dark:bg-bg_dark_white01 mt-2 grid-cols-[270px_260px_auto_120px] px-[20px] py-[18px] cursor-pointer hover:bg-blue_tint02 dark:hover:bg-dark_blue_tint02 dark:border-bg_dark_white01 hover:border-blue dark:hover:border-dark_blue hover:shadow-hover md:grid-cols-2'
                            }>
                            {/*My Key*/}
                            <div className={'flex justify-between items-center'}>
                                <div className={'flex items-center overflow-hidden'}>
                                    <ExchangeIcon src={getExchange(item?.exchange)?.icon} size={25} />

                                    <Text
                                        className={
                                            'text-body1 text-gray01 dark:text-dark_gray01  ml-2 md:w-[105px] truncate sm:w-full'
                                        }
                                        enablePreWhiteSpace={false}>
                                        {item?.copier_name || me?.name}
                                    </Text>
                                </div>
                                <IconNext className={'fill-gray04 w-[16px] ml-[15px]'} />
                                {/*Leader Key*/}
                                <div className={'flex items-center hidden'}>
                                    <ExchangeIcon src={getExchange(item?.exchange)?.icon} size={25} />
                                    <Text
                                        className={
                                            'text-body1 text-gray01 dark:text-dark_gray01 ml-2 md:w-[105px] truncate sm:w-full'
                                        }
                                        enablePreWhiteSpace={false}>
                                        {item?.leader_name}
                                    </Text>
                                </div>
                            </div>
                            <div className={'flex items-center'}>
                                <ExchangeIcon src={getExchange(item?.exchange)?.icon} size={25} />
                                <Text
                                    className={
                                        'text-body1 text-gray01 dark:text-dark_gray01 ml-2 md:w-[105px] truncate sm:w-full'
                                    }
                                    enablePreWhiteSpace={false}>
                                    {item?.leader_name || item?.name}
                                </Text>
                            </div>

                            {/*Period*/}
                            <Text className={'text-body1 text-gray01 dark:text-dark_gray01 sm:hidden'}>
                                {t('copyHistory.table.column.period.content', {
                                    start_date: displayDateFromText(item?.start_at, t('dateFormat.date')),
                                    end_date: displayDateFromText(item?.deleted_at, t('dateFormat.date')),
                                })}
                            </Text>

                            {/*Profit*/}
                            <Text className={`text-ti1 text-right ${getNumberColorClass(item?.earning)} sm:hidden`}>
                                {displayBalance(item?.earning, {showPreSign: true})}
                            </Text>

                            {/*Period Profit on small*/}
                            <div
                                className={
                                    'hidden sm:flex col-span-2 justify-between items-center border-t pt-[13px] border-gray07 dark:border-dark_gray07'
                                }>
                                <Text className={'text-body3 text-gray01 dark:text-dark_gray01'}>
                                    {t('copyHistory.table.column.period.content', {
                                        start_date: displayDateFromText(item?.start_at, t('dateFormat.date')),
                                        end_date: displayDateFromText(item?.deleted_at, t('dateFormat.date')),
                                    })}
                                </Text>
                                <Text className={`text-ti1 text-right ${getNumberColorClass(item?.earning)}`}>
                                    {displayBalance(item?.earning, {showPreSign: true})}
                                </Text>
                            </div>
                        </div>
                    )
                })
            )}
            <CopyHistoryDetailModal ref={historyDetailModalRef} />
        </>
    )
}

export default UserCopyHistoryTable
